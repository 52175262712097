import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const AcesseOsEditaisSection = styled.section`

  .auction-notice {
    &__title {
      font-family: "Citrina", sans-serif;
      font-weight: 500;
      font-size: 28px;
      color: ${grayscale[500]};

      @media ${device.tablet} {
        font-size: 40px;
      }
    }

    &__name {
      font-family: "Citrina", sans-serif;
      font-weight: 600;
      font-size: 18px;   
      color: ${grayscale[500]};

      @media ${device.tablet} {
        font-size: 24px;
        font-weight: 500;
      }

      @media ${device.desktopXL} {
        font-size: 28px;
      }
    }

    &__period {
      font-family: "Citrina", sans-serif;
      font-weight: 600;
      font-size: 16px;   
      color: ${grayscale[500]};

      @media ${device.tablet} {
        font-size: 20px;
        font-weight: 500;
      }
    }

    &__description {
      font-family: "Inter", sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: ${grayscale[400]};

      @media ${device.tablet} {
        font-weight: 500;
      }

      @media ${device.desktopLG} {
        font-size: 18px;
      }
    }

    &__anchor {
      justify-content: space-between;
    }
  }

  .gap-between {
    gap: 16px;
  }

  padding: 40px 0;

  @media ${device.tablet} {
    padding: 56px 0;
  }
`
