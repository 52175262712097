import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import { Wrapper } from './style'
import pageContext from './pageContext.json'

import {
  HeroLeilaoDeCarteirasDeCretido,
  AcesseOsEditais,
  Peac,
} from './_sections/_index'

const ForYourBusiness = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <HeroLeilaoDeCarteirasDeCretido />
        <AcesseOsEditais />
        {/* <Peac /> */}
      </Layout>
    </Wrapper>
  )
}

export default ForYourBusiness
