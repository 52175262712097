import React from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import * as S from './style'
import { ButtonLink } from 'src/components/ButtonLink'
import { orange, white } from 'src/styles/colors'

const AcesseOsEditais = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.AcesseOsEditaisSection>
      <div className='container'>
        <div className='row'>
          <div className='auction-notice col-12'>
            <h2 className='auction-notice__title mb-4 pb-2 mb-md-5 pb-0'>Acesse os editais</h2>
            <h3 className='auction-notice__name mb-2'>DESENROLA BRASIL</h3>
            <p className='auction-notice__description mb-3 pb-3'>
              Os presentes leilões visam à cessão da carteira de créditos do programa Desenrola Brasil do BANCO INTER S.A, através da plataforma Debitumx.
            </p>
            <h3 className='auction-notice__period mb-4'>2º Leilão / 2025</h3>
            <div className='d-flex flex-column flex-md-row gap-between mb-4 pb-3'>
              <ButtonLink
                width='276px'
                height='48px'
                href='https://www.debitumx.com/'
                className='auction-notice__anchor'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'Acessar leilão',
                    section_name: 'Acessar edital',
                    redirect_url: 'https://www.debitumx.com/',
                  })
                }}
              >
                Acessar leilão
                <IconsSwitch icon='ic_chevron_right' lib='interco' customPath='action-navigation/' width='24' height='24' color={white} />
              </ButtonLink>
              <ButtonLink
                width='276px'
                height='48px'
                href='https://marketing.bancointer.com.br/arquivos/pdf/credito/segundo-leilao-desenrola-2025.pdf'
                className='auction-notice__anchor'
                target='_blank'
                rel='noreferrer'
                outline
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'Acessar edital',
                    section_name: 'Acesse os editais',
                    redirect_url: 'https://marketing.bancointer.com.br/arquivos/pdf/credito/segundo-leilao-desenrola-2025.pdf',
                  })
                }}
              >
                Acessar edital
                <IconsSwitch icon='ic_chevron_right' lib='interco' customPath='action-navigation/' width='24' height='24' color={orange[500]} />
              </ButtonLink>
            </div>
            <h3 className='auction-notice__period mb-2'>1º Leilão / 2024</h3>
            <p className='auction-notice__description mb-4'>Em conformidade com o previsto nos incisos VII e X, do Art. 3, da Portaria Normativa Nº 124, de 26 de janeiro de 2024, informamos que a proposta vencedora para cessão dos créditos relativos ao presente Edital foi de R$ 1.425.664,02, correspondente a 1,40000% do saldo contábil e foi apresentada pela empresa Recovery do Brasil Consultoria S.A., inscrita no CNPJ nº 05.032.035/0001-26.</p>
            <div className='d-flex flex-column flex-md-row gap-between mb-5'>
              <ButtonLink
                width='276px'
                height='48px'
                href='https://marketing.bancointer.com.br/arquivos/pdf/credito/Leilao-Desenrola-2024.pdf?_gl=1*1fdmt8d*_gcl_au*MjkxOTI0ODgzLjE3MzM4NDg1Nzc.'
                target='_blank'
                rel='noreferrer'
                className='auction-notice__anchor'
                outline
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'Acessar edital',
                    section_name: 'Acesse os editais',
                    redirect_url: 'https://marketing.bancointer.com.br/arquivos/pdf/credito/Leilao-Desenrola-2024.pdf?_gl=1*1fdmt8d*_gcl_au*MjkxOTI0ODgzLjE3MzM4NDg1Nzc.',
                  })
                }}
              >
                Acessar edital
                <IconsSwitch icon='ic_chevron_right' lib='interco' customPath='action-navigation/' width='24' height='24' color={orange[500]} />
              </ButtonLink>
            </div>
            <h3 className='auction-notice__name mb-2'>PEAC</h3>
            <p className='auction-notice__description mb-4 pb-2'>Em conformidade com o previsto no inciso XIII da resolução CMN Nº 4.971, de 16 de dezembro DE 2021, informamos que a proposta vencedora para cessão dos créditos relativos ao presente Edital foi de R$41.850,48, correspondente a 1,70000% do saldo contábil e foi apresentada pela empresa Intacto Securitizadora de Créditos Financeiros, inscrita no CNPJ nº 42.819.010/0001-01.</p>
            <div className='d-flex flex-column flex-md-row gap-between'>
              <ButtonLink
                width='276px'
                height='48px'
                href='https://marketing.bancointer.com.br/arquivos/pdf/credito/Leilao_Peac_Maquininhas_2024.pdf?_gl=1*1fdmt8d*_gcl_au*MjkxOTI0ODgzLjE3MzM4NDg1Nzc.'
                target='_blank'
                rel='noreferrer'
                className='auction-notice__anchor'
                outline
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'Acessar edital',
                    section_name: 'Acesse os editais',
                    redirect_url: 'https://marketing.bancointer.com.br/arquivos/pdf/credito/Leilao_Peac_Maquininhas_2024.pdf?_gl=1*1fdmt8d*_gcl_au*MjkxOTI0ODgzLjE3MzM4NDg1Nzc.',
                  })
                }}
              >
                Acessar edital
                <IconsSwitch icon='ic_chevron_right' lib='interco' customPath='action-navigation/' width='24' height='24' color={orange[500]} />
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
    </S.AcesseOsEditaisSection>
  )
}

export default AcesseOsEditais
